import { create } from "zustand";

export const useUserInfoStore = create((set) => ({
  userInfo: {},

  // 사용자 정보 업데이트
  updateUserInfo: (info) => {
    set({ userInfo: { ...info } });
  },

  // 사용자 정보 초기화
  initUserInfo: () => {
    set({ userInfo: {} });
  },
}));
